export default {
  path: '/hesap',
  name: 'Account',
  meta: { requiresAuth: true, layout: 'UserLayout' },
  redirect: { name: 'Dashboard' },
  component: () => import(/* webpackChunkName: "dashboard" */ '@/views/Dashboard/AccountView'),
  children: [
    {
      path: 'dashboard',
      name: 'Dashboard',
      component: () => import(/* webpackChunkName: "dashboard" */ '@/views/Dashboard/DashboardView')
    },
    {
      path: 'profil',
      name: 'Profile',
      component: () => import(/* webpackChunkName: "dashboard" */ '@/views/Dashboard/ProfilesView')
    },
    {
      path: 'raporlarim',
      name: 'Reports',
      component: () => import(/* webpackChunkName: "report" */ '@/views/Dashboard/ReportView')
    },
    {
      path: 'raporlar/:id/:page',
      name: 'TestReport',
      component: () => import(/* webpackChunkName: "report" */ '@/views/Dashboard/ReportItemView'),
      meta: { sidebar: 'ReportNavigation' }
    },
    {
      path: 'siparisler',
      name: 'Orders',
      component: () => import(/* webpackChunkName: "dashboard" */ '@/views/Dashboard/OrdersView')
    },
    {
      path: 'siparisler/:id',
      name: 'Order',
      component: () => import(/* webpackChunkName: "dashboard" */ '@/views/Dashboard/OrdersView')
    },
    {
      path: 'matcher',
      name: 'Matcher',
      component: () => import(/* webpackChunkName: "dashboard" */ '@/views/Dashboard/MatcherView')
    },
    {
      path: 'matcher/filter',
      name: 'MatcherFilter',
      component: () => import(/* webpackChunkName: "dashboard" */ '@/views/Dashboard/MatcherFilterView')
    },
    {
      path: 'matcher/filter/result',
      name: 'MatcherFilterResult',
      component: () => import(/* webpackChunkName: "dashboard" */ '@/views/Dashboard/MatcherFilterResultView'),
      meta: { first: false, second: false }
    },
    {
      path: 'favoriler',
      name: 'Favorites',
      component: () => import(/* webpackChunkName: "dashboard" */ '@/views/Dashboard/FavoriteView')
    }
  ]
}
