export default [
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "home" */ '@/views/Public/HomeView')
  },
  {
    path: '/ucretsiz-testler/:test',
    name: 'Tests',
    props: true,
    component: () => import(/* webpackChunkName: "tests" */ '@/views/Public/TestsView')
  },
  {
    path: '/test/:test',
    name: 'Test',
    props: true,
    meta: { layout: 'PremiumLayout' },
    component: () => import(/* webpackChunkName: "premiumTests" */ '@/views/Public/TestsView')
  },
  {
    path: '/raporlar',
    name: 'Catalog',
    component: () => import(/* webpackChunkName: "prices" */ '@/views/Public/PricesView')
  },
  {
    path: '/raporlar/:productSlug',
    name: 'Products',
    component: () => import(/* webpackChunkName: "products" */ '@/views/Public/ProductView')
  },
  {
    path: '/kaynaklar/16-profiles-kisilik-tipi',
    name: 'profile',
    component: () => import(/* webpackChunkName: "profiles" */ '@/views/Public/ResourceProfilesView'),
    children: [
      {
        path: ':profile',
        name: 'profileInfo',
        props: true,
        component: () => import(/* webpackChunkName: "profiles" */ '@/views/Public/ResourceProfilesView')
      }
    ]
  },
  {
    path: '/kaynaklar/16-profiles',
    name: 'profiles',
    component: () => import(/* webpackChunkName: "profiles" */ '@/views/Public/TestPageView')
  },
  {
    path: '/kaynaklar/meslek',
    name: 'carrier',
    component: () => import(/* webpackChunkName: "profiles" */ '@/views/Public/TestPageView')
  },
  {
    path: '/kaynaklar/degerler',
    name: 'values',
    component: () => import(/* webpackChunkName: "profiles" */ '@/views/Public/TestPageView')
  },
  {
    path: '/tercih-sihirbazi',
    name: 'Wizard',
    component: () => import(/* webpackChunkName: "yks" */ '@/views/Yks/YksView')
  },
  {
    path: '/tercih-sihirbazi/taban-puanlari-ve-basari-siralamalari',
    name: 'PointOrders',
    component: () => import(/* webpackChunkName: "yks" */ '@/views/Yks/OrdersView')
  },
  {
    path: '/tercih-sihirbazi/yks-tyt-ayt-puan-hesaplama-ve-siralama-hesaplama',
    name: 'NetWizard',
    component: () => import(/* webpackChunkName: "yks" */ '@/views/Yks/NetView')
  },
  {
    path: '/tercih-sihirbazi/listelerim',
    name: 'WizardList',
    meta: { requiresAuth: true },
    component: () => import(/* webpackChunkName: "yks" */ '@/views/Yks/YksListView')
  },
  {
    path: '/hakkimizda',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '@/views/Public/AboutView')
  },
  {
    path: '/blog',
    name: 'blog',
    component: () => import(/* webpackChunkName: "blog" */ '@/views/Public/BlogView')
  },
  {
    path: '/blog/:article',
    name: 'article',
    props: true,
    component: () => import(/* webpackChunkName: "blog" */ '@/views/Public/BlogItemView')
  },
  {
    path: '/:notFound(.*)*',
    name: 'notFound',
    component: () => import(/* webpackChunkName: "notFound" */ '@/views/Public/NotFoundView')
  }
]
